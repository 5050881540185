import AdvText from "@components/data/text";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvTextInput from "@components/inputs/text-input";
import AdvModal, { TAdvModalProps } from "@components/layout/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import AdvLoading, { EAdvLoadingMode, EAdvLoadingPosition } from "@components/other/loading";
import { EAdvSpinnerSize } from "@components/other/spinner";
import { LAN } from "@data/language/strings";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useState } from "react";

export type TTwoFactorAuthModalProps = Pick<TAdvModalProps, "isOpen" | "styles"> & {
    onClosed(token?: string): void;
};

const TwoFactorAuthInputModal = ({ onClosed, ...props }: TTwoFactorAuthModalProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [token, setToken] = useState("");

    useAdvEffect(() => {
        if (props.isOpen == false) {
            setToken("");

            setErrorMessage(undefined);
            setLoading(false);
        }
    }, [props.isOpen]);

    const handleDismiss = () => {
        onClosed(undefined);
    };

    const handleAuthenticate = () => {
        if (token == "") setErrorMessage("Feld leer!");
        else onClosed(token);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key.toLowerCase() == "enter") {
            void handleAuthenticate();
        } else if (event.key.toLowerCase() == "escape") {
            handleDismiss();
        }
    };

    return (
        <AdvModal
            isModeless={true}
            isDarkOverlay={true}
            onDismiss={handleDismiss}
            headline={LAN.TWOFACTOR_HEADER.text}
            translationContext={LAN.TWOFACTOR_HEADER.context}
            {...props}
        >
            <AdvStack verticalAlign="space-between" verticalFill>
                <AdvStackItem>
                    <AdvTextInput
                        key="code"
                        label={LAN.ONETIME_CODE.text}
                        translationContext={LAN.ONETIME_CODE.context}
                        required
                        value={token}
                        onValueChanged={(value) => setToken(value ?? "")}
                        disabled={isLoading}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        errorMessage={errorMessage}
                    />
                    <AdvText block variant="small">
                        {LAN.TWOFACTOR_CHECK_APP.text}
                    </AdvText>
                </AdvStackItem>
                <AdvStackItem>
                    <AdvStack horizontal style={{ justifyContent: "space-between" }}>
                        <AdvButtonPure
                            key="close"
                            text={LAN.CANCEL.text}
                            translationContext={LAN.CANCEL.context}
                            onClick={handleDismiss}
                        />
                        <AdvLoading
                            isLoading={isLoading}
                            spinnerProps={{ size: EAdvSpinnerSize.large }}
                            mode={EAdvLoadingMode.ShowLoadingAtPosition}
                            position={EAdvLoadingPosition.BeforeContent}
                        />
                        <AdvButtonPure
                            key="auth"
                            text={LAN.AUTHENTICATE.text}
                            translationContext={LAN.AUTHENTICATE.context}
                            primary
                            disabled={isLoading}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleAuthenticate}
                        />
                    </AdvStack>
                </AdvStackItem>
            </AdvStack>
        </AdvModal>
    );
};

export default TwoFactorAuthInputModal;
