import AdvText from "@components/data/text";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvCheckbox from "@components/inputs/checkbox";
import AdvTextInput from "@components/inputs/text-input";
import AdvModal, { TAdvModalProps } from "@components/layout/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { LAN } from "@data/language/strings";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useState } from "react";
import QRCode from "react-qr-code";

export type TTwoFactorAuthCreateModalProps = Pick<TAdvModalProps, "isOpen" | "styles"> & {
    onClosed(activate2FA?: boolean, token?: string): void;
    username: string;
    secret: string;
    shouldForce2FA: boolean;
};

function generateOtpKeyUri(secret: string, username: string, issuer: string = "AdvanTex") {
    return `otpauth://totp/${issuer}:${username}?secret=${secret}&issuer=${issuer}`;
}

const TwoFactorAuthCreateModal = ({
    username,
    secret,
    onClosed,
    shouldForce2FA,
    ...props
}: TTwoFactorAuthCreateModalProps) => {
    const [shouldActivate2FA, setActivate2FA] = useState(true); // Soll 2FA aktiviert werden?
    const [token, setToken] = useState(""); // Erster 2FA Code

    useAdvEffect(() => {
        if (props.isOpen == false) {
            setToken("");
        }
    }, [props.isOpen]);

    const handleDismiss = () => {
        onClosed(undefined, undefined);
    };

    const handleContinue = () => {
        onClosed(shouldActivate2FA, token);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key.toLowerCase() == "enter") {
            void handleContinue();
        } else if (event.key.toLowerCase() == "escape") {
            handleDismiss();
        }
    };

    return (
        <AdvModal
            isModeless={true}
            isDarkOverlay={true}
            onDismiss={handleDismiss}
            headline={LAN.TWOFACTOR_CONFIGURE.text}
            translationContext={LAN.TWOFACTOR_CONFIGURE.context}
            {...props}
        >
            <AdvStack verticalAlign="space-between" verticalFill>
                {shouldActivate2FA && (
                    <AdvStackItem
                        align="center"
                        styles={{
                            root: {
                                margin: "1em",
                                border: "white 1em solid",
                                background: "white",
                            },
                        }}
                    >
                        <QRCode value={generateOtpKeyUri(secret, username)} />
                    </AdvStackItem>
                )}
                <AdvStackItem>
                    {!shouldForce2FA && (
                        <AdvCheckbox
                            label={LAN.TWOFACTOR_ACTIVATE.text}
                            translationContext={LAN.TWOFACTOR_ACTIVATE.context}
                            value={shouldActivate2FA}
                            onValueChanged={(value) => setActivate2FA(value ?? true)}
                        />
                    )}
                </AdvStackItem>
                {shouldActivate2FA && (
                    <AdvStackItem align="start" styles={{ root: { padding: 4 } }}>
                        <AdvText block styles={{ root: { fontWeight: "bold" } }}>
                            Anleitung
                        </AdvText>
                        <AdvText block>
                            {LAN.STEP_1.text}:{" "}
                            <a
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                rel="noreferrer"
                            >
                                {LAN.GOOGLEAUTH_DOWNLOAD.text}
                            </a>{" "}
                        </AdvText>
                        <AdvText block>
                            {LAN.STEP_2.text}: {LAN.GOOGLEAUTH_START.text}
                        </AdvText>
                        <AdvText block>
                            {LAN.STEP_3.text}: {LAN.SCAN_QRCODE.text}
                        </AdvText>
                        <AdvText block>
                            {LAN.STEP_4.text}: {LAN.ENTER_OTP.text}
                        </AdvText>
                    </AdvStackItem>
                )}
                {shouldActivate2FA == false && (
                    <AdvStackItem align="start" styles={{ root: { padding: 4 } }}>
                        <AdvText block styles={{ root: { color: "orange", fontWeight: "bold" } }}>
                            {LAN.TWOFACTOR_RECOMMENDED.text}
                        </AdvText>
                        <AdvText block styles={{ root: { color: "orange", fontWeight: "bold" } }}>
                            {LAN.TWOFACTOR_THEFT.text}
                        </AdvText>
                    </AdvStackItem>
                )}
                {shouldActivate2FA && (
                    <AdvStackItem>
                        <AdvTextInput
                            key="code"
                            label={LAN.ONETIME_CODE.text}
                            translationContext={LAN.ONETIME_CODE.context}
                            required
                            value={token}
                            onValueChanged={(value) => setToken(value ?? "")}
                            onKeyDown={handleKeyDown}
                            autoFocus
                        />
                    </AdvStackItem>
                )}
                <AdvStackItem align="end">
                    <AdvStack horizontal style={{ justifyContent: "space-between" }}>
                        <AdvButtonPure
                            key="auth"
                            text={LAN.CONTINUE.text}
                            translationContext={LAN.CONTINUE.context}
                            primary
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleContinue}
                        />
                    </AdvStack>
                </AdvStackItem>
            </AdvStack>
        </AdvModal>
    );
};

export default TwoFactorAuthCreateModal;
